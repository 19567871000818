import React from 'react';
import { FeedType } from '../models/feedType';
import { getLocalStorage, setLocalStorage } from '../utils/localStorage';
import { LocalStorageKey } from '../models/localStorage';
import { getQueryString } from '../utils/queryString';
import { QueryStringKey } from '../models/queryString';
import FeedDetailViewPage from '../components/pages/FeedDetail/ViewPage';
import { changeEnableScroll } from '../utils/bridge';
import { decodeJwt } from 'jose';
import { getNewAccessToken } from '../utils/accessToken';

export default function FeedDetailView() {
  React.useEffect(() => {
    changeEnableScroll(true);
  }, []);

  const accessTokenRef = React.useRef(
    getLocalStorage(LocalStorageKey.ACCESS_TOKEN)
  );
  const channelId = getQueryString(QueryStringKey.CHANNEL_ID);
  const feedId = getQueryString(QueryStringKey.FEED_ID);
  const feedType: FeedType = (() => {
    const originalFeedType = getQueryString(QueryStringKey.FEED_TYPE);

    if (originalFeedType === 'creator') return FeedType.CREATOR;
    if (originalFeedType === 'user') return FeedType.USER;

    return FeedType.USER; // 2022-10-24 기준 커뮤니티(유저피드)만 작성 가능
  })();

  const accessTokenRefreshTimeoutRef = React.useRef<
    ReturnType<typeof setTimeout> | undefined
  >();

  const runAutoRefreshToken = (prevToken: string) => {
    const { exp } = decodeJwt(prevToken);

    if (!exp) return;
    accessTokenRefreshTimeoutRef.current = setTimeout(() => {
      window.Realworld.refreshToken();
      getNewAccessToken(prevToken).then((nextToken) => {
        if (nextToken) {
          accessTokenRef.current = nextToken;
          runAutoRefreshToken(nextToken);
        }
      });
    }, Math.max(exp * 1000 - Date.now() - 1000 * 60 * 1, 0));
  };

  React.useEffect(() => {
    const prevToken = getLocalStorage(LocalStorageKey.ACCESS_TOKEN);

    if (!prevToken) return;

    runAutoRefreshToken(prevToken);

    return () => {
      if (accessTokenRefreshTimeoutRef.current) {
        clearTimeout(accessTokenRefreshTimeoutRef.current);
        accessTokenRefreshTimeoutRef.current === undefined;
      }
    };
  }, []);

  if (!channelId || !feedId) {
    // 404로 보내기 혹은 웹뷰 종료
    console.error(channelId, feedId);
    return <></>;
  }

  return (
    <FeedDetailViewPage
      accessTokenRef={accessTokenRef}
      channelId={channelId}
      feedType={feedType}
      feedId={feedId}
    />
  );
}
